'use strict';

class UxrFeDynamic extends React.Component {
	constructor(props) {
		super(props);
		//this.state = {};
		
		this.onAdd = this.onAdd.bind(this);
		this.onRemove = this.onRemove.bind(this);
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		let content = this.renderMultiContent(this.props.value || []);
		let frameProps = this.props.frame;
		
		return (
			<div className="uxrFe uxrFeDynamic MVT_list" {...frameProps.htmlProps}>
				<div className="descriptor formFlow">
					{frameProps.label && <label className="MVT_onClickAsIcon">{frameProps.label}</label>}
					{frameProps.hint && <label className="inputEntity_hint" role="hint"><span>{frameProps.hint}</span></label>}
				</div>
				<div className="MVT_item" style={{paddingLeft:'0.7em'}}>
					{content}
					<input type="button" value="+" className="uxr_actionButton uxrFeDynamic_actionAddEntry" onClick={this.onAdd} />
				</div>
				<label className="inputEntity_alert" role="alert"></label>
			</div>
		);
	};
	
	/*---------------------------------------------------------------------*/
	onAdd() {
		console.log(this.props.idInHierarchy);
		this.props.onChange(this, {add: this.props.idInHierarchy});
	}
	
	/*---------------------------------------------------------------------*/
	onRemove(idx) {
		this.props.onChange(this, {remove: idx});
	}
	
	/*---------------------------------------------------------------------*/
	renderSingleContent(value, index) {
		let idInHierarchy = ''
			+ this.props.idInHierarchy
			+ (index===undefined? '': '['+index+']')
		
		let Content = this.props.content.type;
		return (
			<div key={idInHierarchy} >
				<Content {...this.props.content} value={value} idInHierarchy={idInHierarchy} onChange={this.props.onChange} />
				<input type="button" value="&times;" className="uxr_actionButton uxrFeDynamic_actionRemoveEntry" onClick={_.partial(this.onRemove, index)} />
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	renderMultiContent(values) {
		let result = [];
		
		for (let i=0; i<values.length; i++) {
			let eachValues = values[i];
			let eachContent = this.renderSingleContent(eachValues, i);
			result.push(eachContent);
		}
		
		return result;
	}
	
	/*---------------------------------------------------------------------*
	componentDidMount() {
	}
	
	/*---------------------------------------------------------------------*
	componentWillUnmount() {
	}
	
	/*---------------------------------------------------------------------*/
} //class
